
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$audit-arquitectura-primary: mat-palette($mat-indigo);
$audit-arquitectura-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$audit-arquitectura-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$audit-arquitectura-theme: mat-light-theme($audit-arquitectura-primary, $audit-arquitectura-accent, $audit-arquitectura-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($audit-arquitectura-theme);

/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import "~@ng-select/ng-select/themes/material.theme.css";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$floresCent-primary: mat-palette($mat-indigo);
$floresCent-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$floresCent-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$floresCent-theme: mat-light-theme($floresCent-primary, $floresCent-accent, $floresCent-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($floresCent-theme);

/* You can add global styles to this file, and also import other style files */
/*
    DEMO STYLE
*/
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

.imgTable {
    width: 50px;
    height: 50px;
}
table {
    width: 100%;
}
  
tr.detail-row {
    height: 0 !important;
}
  
tr.element-row:not(.expanded-row):hover {
    background: whitesmoke;
}
  
tr.element-row:not(.expanded-row):active {
    background: #efefef;
}
.element-row td {
    border-bottom-width: 0;
}
  
.element-detail {
    overflow: hidden;
    display: flex;
}

*, *:focus {
    outline: none !important;
    box-shadow: none !important;
}
.bold {
    font-weight: bold;
}

html, body {
    min-height: 100vh;
}
.hidden {
    visibility: hidden;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
}

.btn-primary {
    color: #fff;
    background-color: #353535;
    border-color: #353535;
}
.btn-link {
    font-weight: bold;
    color: #666;
    &:hover, &:focus {
        color: #000;
        text-decoration: none;
    }
}


#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #a7a7a7;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: bottom left;
}

#sidebar.active {
    margin-left: -250px;
    transform: rotateY(100deg);
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #a7a7a7;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: none;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #a7a7a7;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}


a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #a7a7a7;
}

a.article, a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
}

#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}
#sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
}


#sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
        transform: rotateY(90deg);
    }
    #sidebar.active {
        margin-left: 0;
        transform: none;
    }
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
        transform: none;
        opacity: 1;
        margin: 5px auto;
    }
    #sidebarCollapse.active span {
        margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
        transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
        transform: rotate(-45deg) translate(1px, -1px);
    }

}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
button.mat-focus-indicator.btn.btn-primary.mat-raised-button.mat-button-base.mat-primary {
    background-color: black !important;
}


/* global theming styles */
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.2);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #000;
}
.mat-raised-button.mat-accent {
    background-color: #000;
}
.card-header {
    padding: 5px !important;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0 !important;
}
.data-type {
    background: rgb(0, 0, 0);
    color: #fff;
    &.active {
        background: #353535;
        color: #fff;
        &:hover {
            background: #2a2a2a;
        }
    }
    &:hover {
        cursor: pointer;
        background: rgb(0, 0, 0);
    }
}
.pointer:hover {
    cursor: pointer;
}
.modal-backdrop.show {
    display: none;
}